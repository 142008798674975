const networkName = "mainnet"
const chainId = 1
const netId = 1
const providerUrl = 'https://eth-mainnet.alchemyapi.io/v2/0DYeLgv-gOg8eigTuC7seAP_1XNggI12'

module.exports = {
    config: {
        networkName: networkName,
        chainId: chainId,
        netId: netId,

        apiUrl: "https://api.parula.io/v1",
        apiKey: '',

        // provider:  metamask need
        readonlyProviderUrl: providerUrl,

        nftAddress: '0x5A6d7399998765a5bd5CA5380C83bDa43b5d7c7a',
        nft1155Address: '0xD78d7773267A3a4d96fB377b4AD195fF9b203E01',
        
        exchangeAddress: '0x916F4c132EA791b270903726d43c8534B5934569',
        royaltiesRegistryAddress: '0x0f334F73C58BB4bf9e1Cf884B6bFaF78a9ACAC2d',
        transferProxyAddress: '0x920623f54d4F2dA0ed1862E70c0464A1ccB0C465',
        erc20TransferProxyAddress: '0x97b6dB1F74D3AAF42b7822EAe277Bc5F5feAfA2E',
        communityWalletAddress: '0x19d3600b0f3074523eDF97421a95ACB0eBf32FEf',
    }
}
