exports.ZERO_ADDRESS = '0x0000000000000000000000000000000000000000'
exports.NULL_BLOCK_HASH = '0x0000000000000000000000000000000000000000000000000000000000000000'

exports.ETH = '0xaaaebeba' // id("ETH") // keccak256(str)..substr(0, 8)
exports.ERC20 = '0x8ae85d84' // id("ERC20")
exports.ERC721 = '0x73ad2146' //id("ERC721")
exports.ERC1155 = '0x973bb640'// id("ERC1155")

// exports.KLAY = '0xb6b072d2' // id('KLAY')   const ethUtil = require('ethereumjs-util'); `0x${ethUtil.keccak256(Buffer.from('KLAY', "utf-8")).toString("hex").substring(0, 8)}`;
exports.KLAY = '0xaaaebeba' // 컨트랙트 코드를 단순하게 유지하기 위해, 그냥 ETH랑 똑같이 간다.

exports.ORDER_DATA_V1 = '0x4c234266' // id("V1")

exports.TYPE_ERC20 = 20     // '0020'
exports.TYPE_ERC721 = 721   // '0721'
exports.TYPE_ERC1155 = 1155 // '1155'
exports.TYPE_MARKETPLACE = 100 // 'MKPL'
