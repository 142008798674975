const networkName = "ropsten"
const chainId = 3
const netId = 3
const providerUrl = 'https://ropsten.infura.io/v3/b6ae638ae8aa47c996394354e364014e'


module.exports = {
    config: {
        networkName: networkName,
        chainId: chainId,
        netId: netId,

        apiUrl: 'https://api.cilo.kr/v1',  // dev-product
        apiKey: '',

        readonlyProviderUrl: providerUrl,

        nftAddress: '0x2FBDC8653927e486d31E8e5b2c4fb6b152A030c8',
        nft1155Address: '0x0f7BA137DF62466b8572abFBF4C796671dF95bdD',
        
        exchangeAddress: '0x752F90380840fBd07Fd8ddB0Bec63A3254bDa688',
        royaltiesRegistryAddress: '0xb1aD37053D66C9Ac18434C818C529B1818830f67',
        transferProxyAddress: '0xD59DFAc3DE1D1359147FE6CB39753CE14b3A0B5e',
        erc20TransferProxyAddress: '0x09e089E18fEF66B1097CB9f3b02EEe81e5EC721F',
        communityWalletAddress: '0x1F33dBC958e70b3467716b283C5c49708f3bA2dB',
    }
}
