const Caver = require('caver-js')

const networkName = "cypress"
const chainId = 8217
const netId = 8217

const providerUrl = "https://node-api.klaytnapi.com/v1/klaytn";
try{

    const {accessKeyId, secretAccessKey, privateKey} = require(`${process.env.HOME}/.klaytn/cypress_deployer`)
    
    const option = {
        headers: [
            {name: 'Authorization', value: 'Basic ' + Buffer.from(accessKeyId + ':' + secretAccessKey).toString('base64')},
            {name: 'x-chain-id', value: '1001'},
        ]
    }
    
    const provider = new Caver.providers.HttpProvider(providerUrl, option)
    provider.chainId = chainId
}catch(e){
    console.error(e)
}

module.exports = {
    config:{
        networkName: networkName,
        chainId: chainId,
        netId: netId,

        apiUrl: 'https://api.cilo.io/v1',  // prod-product   
        apiKey: '',

        readonlyProviderUrl: providerUrl,

        nftAddress: '0x279e2A8ED0479a20D6daeEC89d6aCe04c1E9F195',
        nft1155Address: '0xC1d3622C5897299F5b9b7e02D5F7647D2a0B154c',
        
        exchangeAddress: '0x6a2546E41412CaaeC86eF703675398634A829193',
        royaltiesRegistryAddress: '0x819743514726cEdC0C75379c28E72D173a83aE14',
        transferProxyAddress: '0x200Af6C6b26Ec55F75f5a33cdD2892fb1FE97eD0',
        erc20TransferProxyAddress: '0xbe16A259d993ED21d5ED0e232C40DC1eF48962C6',
        communityWalletAddress: '0xbbd546281301CCCF287173fB2437C387aDeC16b6',
    },
}
