const HDWalletProviderKlaytn = require('truffle-hdwallet-provider-klaytn');

const networkName = "baobab"
const chainId = 1001
const netId = 1001


const providerUrl = "https://api.baobab.klaytn.net:8651";
const pvKeys = ['5239d77e1a412282001266717accd0681efe653ee0350a1221094689fbdbbeec'] // sample

try{
    const {alicePrivateKey, bobPrivateKey} = require(`${process.env.HOME}/.klaytn/baobab_deployer`)
    pvKeys.push(alicePrivateKey)
    pvKeys.push(bobPrivateKey)
}catch(err){
    console.log('[!] baobab_deployer.js not found')
}

let provider = new HDWalletProviderKlaytn({
    privateKeys: pvKeys,
    providerOrUrl: providerUrl,
    chainId
})


module.exports = {
    config:{
        networkName: networkName,
        chainId: chainId,
        netId: netId,

        apiUrl: 'https://api.cilo.kr/v1',  // dev-product   
        apiKey: '',

        readonlyProviderUrl: providerUrl,

        nftAddress: '0xFEe780cA54D27B7e39F010fE424be910D48081Cd',
        nft1155Address: '0xcCC2b61C71402172F72Be757Fa14d3a557372f4b',
        
        exchangeAddress: '0x7C33a7635734bfBCF925EC77aB7AE30d2EDC91eC',
        royaltiesRegistryAddress: '0x61A02F80f1E56681f37C814497649399cEA25Fd3',
        transferProxyAddress: '0x771565ACd561DEf7E71529382aa32CDAE5E8844b',
        erc20TransferProxyAddress: '0x627d521C1D4F00488C3CBa4dc060f523F5a96576',
        communityWalletAddress: '0xbbd546281301CCCF287173fB2437C387aDeC16b6',
    },
    provider: provider,
    ALICE_ADDRESS: "0x7a450816d74fd8e1ca20e9e015064af6e9c760b2",
    BOB_ADDRESS: "0xe617464c1ab3aa4db1b734c6f10e49a8188b2e1d",
    TESTERC20_ADDRESS: "0xe06c059cb4240bbec882f9d0e15c926e90091416"
}
