const networkName = "rinkeby"
const chainId = 4
const netId = 4
const providerUrl = 'https://eth-rinkeby.alchemyapi.io/v2/qgSA05UjKWC0SDBdcBGNMVOosOzSxEJ2'


module.exports = {
    config:{
        networkName: networkName,
        chainId: chainId,
        netId: netId,

        apiUrl: 'https://api.parula.kr/v1',  // dev-product   
        apiKey: '',

        readonlyProviderUrl: providerUrl,

        nftAddress: '0x72d89B7FB7Ec49F3E8BDaEA02A5a009B1cbd2b6F',
        nft1155Address: '0x421b8189992791178e6f510db34086FB9e9ee3ce',
        
        exchangeAddress: '0xbC752EcEAaB83a8dE25500AfA4597dDC0C030480',
        royaltiesRegistryAddress: '0x24CD275aa022b5A35378B40C3D231C201B6Cd884',
        transferProxyAddress: '0x192e69D6F6ec4F4153D9FAc676162362877FC9e4',
        erc20TransferProxyAddress: '0xDc5D82454dB33b8A9b913cCDA7Ac0da26FB88108',
        communityWalletAddress: '0xFC016457a887672a37170e9F7969aBC9207f711F',
    },
}